.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
    background-color: #f5f5f5;
  }
  
  .nav-link {
    color: #e56d90;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    margin: 0 1rem;
  }