.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .logo {
    max-width: 300px;
    max-height: 300px;
  }
  
  .subtitle {
    font-size: 1.5rem;
    margin-top: 1rem;
    color: #e56d90;
  }